import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Header, Loader1 } from "../../../global";
import { collection, doc, getDoc, getDocs } from "firebase/firestore"; // Import Firestore functions for fetching data
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../hooks/firebase";

import { db } from "../../../../hooks/firebase"; // Adjust the path to your firebase.js file

const Issue = () => {
  const { issueCode } = useParams(); // Get the issueCode from the URL parameter

  const [currentIssue, setCurrentIssue] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [user] = useAuthState(auth);
  const [allowedUserUids, setAllowedUserUids] = useState([]); // State to store allowed user UIDs

  useEffect(() => {
    // Function to fetch allowedUserUids from the "Admins" collection
    const fetchAllowedUserUids = async () => {
      try {
        const adminsCollection = collection(db, "admins");
        const adminsSnapshot = await getDocs(adminsCollection);

        const uids = adminsSnapshot.docs.map((doc) => {
          const data = doc.data();
          return data.userId;
        });

        setAllowedUserUids(uids);
      } catch (error) {
        console.error("Error fetching allowed user UIDs: ", error);
      }
    };

    fetchAllowedUserUids(); // Fetch allowedUserUids when the component mounts

    const fetchIssue = async () => {
      try {
        // Create a reference to the specific issue document in Firestore
        const issueRef = doc(db, "issues", issueCode);

        // Fetch the data for the specific issue
        const issueDoc = await getDoc(issueRef);

        // Check if the document exists
        if (issueDoc.exists()) {
          setCurrentIssue(issueDoc.data());
          setIsLoading(false);
        } else {
          console.error("Issue not found");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching issue: ", error);
        setIsLoading(false);
      }
    };

    fetchIssue();
  }, [issueCode]);

  return (
    <>
      <div className="container__page issue">
        <div className="page__wrapper">
          <Header />
          {isLoading ? (
            <Loader1 />
          ) : (
            <div className="wrapper__issue">
              <Link className="issue__back" to="/tech-issues">
                Back
              </Link>
              {currentIssue ? (
                <>
                  <h1>{currentIssue.title}</h1>
                  <h2>Error: {issueCode}</h2>
                  {currentIssue.howToReproduce && (
                    <p className="issue__text">
                      How to Reproduce: {currentIssue.howToReproduce}
                    </p>
                  )}
                  {currentIssue.output && (
                    <p className="issue__text">Output: {currentIssue.output}</p>
                  )}
                  {currentIssue.triedFixes &&
                    currentIssue.triedFixes.length > 0 && (
                      <div
                        className="issue__text"
                        style={{ marginBottom: "20px" }}
                      >
                        <h3>Tried Fixes:</h3>
                        <ul>
                          {Array.isArray(currentIssue.triedFixes) ? (
                            currentIssue.triedFixes.map((fix, index) => (
                              <li key={index}>{fix}</li>
                            ))
                          ) : (
                            <li>No tried fixes available</li>
                          )}
                        </ul>
                      </div>
                    )}
                  {currentIssue.fixes.length > 0 && (
                    <div className="issue__text">
                      <h3>Working Fixes:</h3>
                      <ul>
                        {currentIssue.fixes.map((fix, index) => (
                          <li key={index}>{fix}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <span className="issue__date">{currentIssue.date}</span>
                </>
              ) : (
                <div>Issue not found</div>
              )}

              {user && allowedUserUids.includes(user.uid) ? (
                <Link
                  className="link__uni"
                  to={`/tech-issues/edit/${issueCode}`}
                  style={{ marginTop: "20px" }}
                >
                  Edit Issue
                </Link>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Issue;
