import React, { useState, useEffect } from "react";
import { collection, addDoc, updateDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../../../hooks/firebase";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const TaskForm = () => {
  const { issueCode } = useParams(); // Get the issueCode from the route parameter

  const [errorTitle, setErrorTitle] = useState("");
  const [howToReproduce, setHowToReproduce] = useState("");
  const [errorOutput, setErrorOutput] = useState("");
  const [errorTriedFixesInput, setErrorTriedFixesInput] = useState("");
  const [fixes, setFixes] = useState([]);
  const [triedFixes, setTriedFixes] = useState([]); // Initialize as an empty array
  const [errorFixesInput, setErrorFixesInput] = useState("");
  const [error, setError] = useState("");
  const [errorCode, setErrorCode] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (issueCode) {
      // If issueCode is provided, fetch the existing task data
      fetchTaskData(issueCode);
    } else {
      setErrorTitle("");
      setHowToReproduce("");
      setErrorOutput("");
      setFixes([]);
      setErrorFixesInput("");
      setError("");
      setErrorCode("");
      setTriedFixes([]); // Initialize as an empty array when issueCode is not provided
    }
  }, [issueCode]);

  const fetchTaskData = async (id) => {
    try {
      const taskDoc = doc(db, "issues", id);
      const taskSnapshot = await getDoc(taskDoc);

      if (taskSnapshot.exists()) {
        const taskData = taskSnapshot.data();
        // Populate the form fields with existing task data
        setErrorTitle(taskData.title || "");
        setHowToReproduce(taskData.howToReproduce || "");
        setErrorOutput(taskData.output || "");
        setFixes(taskData.fixes || []);
        setErrorFixesInput("");
        setError(taskData.error || "");
        setErrorCode(taskData.errorCode || "");
        setTriedFixes(taskData.triedFixes || []); // Initialize as an empty array if not provided
      }
    } catch (error) {
      console.error("Error fetching task data: ", error);
    }
  };

  const addReward = () => {
    if (errorFixesInput.trim() !== "" && !fixes.includes(errorFixesInput)) {
      setFixes([...fixes, errorFixesInput]);
      setErrorFixesInput("");
    }
  };

  const addObjective = () => {
    if (errorTriedFixesInput.trim() !== "" && !fixes.includes(errorTriedFixesInput)) {
      setTriedFixes([...triedFixes, errorTriedFixesInput]);
      setErrorTriedFixesInput("");
    }
  };

  const handleTaskSubmit = async (e) => {
    e.preventDefault();

    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString();

      if (issueCode) {
        // If issueCode is provided, update the existing task
        const taskDocRef = doc(db, "issues", issueCode);
        await updateDoc(taskDocRef, {
          title: errorTitle,
          error: error,
          errorCode: errorCode,
          output: errorOutput,
          triedFixes: triedFixes,
          howToReproduce: howToReproduce,
          fixes: fixes,
          date: formattedDate,
        });
      } else {
        // If issueCode is not provided, add a new task
        const docRef = await addDoc(collection(db, "issues"), {
          title: errorTitle,
          error: error,
          errorCode: errorCode,
          output: errorOutput,
          triedFixes: triedFixes,
          howToReproduce: howToReproduce,
          fixes: fixes,
          date: formattedDate,
        });
        console.log("Task added with ID: ", docRef.id);
      }

      // Clear the form
      setErrorTitle("");
      setHowToReproduce("");
      setFixes([]);
      setErrorFixesInput("");
      setTriedFixes([]);
      setError("");
      setErrorCode("");

      // Navigate back to the previous page
      navigate(-1);
    } catch (error) {
      console.error("Error adding/updating task: ", error);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="container__page dashboard subpage" style={{ paddingBottom: "20vh" }}>
      <button className="backButtonUni" onClick={goBack}>
        Go Back
      </button>
      <Link className="home__Link" to="/" style={{ marginBottom: "20px" }}>
        Home
      </Link>
      <div className="dashboard__container">
        <form onSubmit={handleTaskSubmit} style={{ zIndex: "+2" }}>
          <input
            type="text"
            placeholder="Error Title"
            value={errorTitle}
            onChange={(e) => setErrorTitle(e.target.value)}
          />
          <input
            type="text"
            placeholder="Error"
            value={error}
            onChange={(e) => setError(e.target.value)}
          />
          <input
            type="text"
            placeholder="Error Code"
            value={errorCode}
            onChange={(e) => setErrorCode(e.target.value)}
          />
          <div>
            <input
              type="text"
              placeholder="Tried Fixes"
              value={errorTriedFixesInput}
              onChange={(e) => setErrorTriedFixesInput(e.target.value)}
            />
            <button type="button" onClick={addObjective}>
              Add Tried Fix
            </button>
          </div>
          {triedFixes.map((objective, index) => (
            <div key={index}>
              <input type="text" value={objective} style={{ background: "none", margin: "0" }} readOnly />
            </div>
          ))}
          <div>
            <input
              type="text"
              placeholder="Working Fixes"
              value={errorFixesInput}
              onChange={(e) => setErrorFixesInput(e.target.value)}
            />
            <button type="button" onClick={addReward}>
              Add Working Fix
            </button>
          </div>
          {fixes.map((reward, index) => (
            <div key={index}>
              <input type="text" style={{ outline: "none", background: "none" }} value={reward} readOnly />
            </div>
          ))}
          <div className="divider"></div>
          <button type="submit">{issueCode ? "Update Task" : "Add Task"}</button>
        </form>
      </div>
    </div>
  );
};

export default TaskForm;
