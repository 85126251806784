import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../../hooks/firebase";
import { Link, useNavigate } from "react-router-dom";
import { Loader1 } from "../../global/Global";
import { collection, getDocs } from "firebase/firestore";

const Dashboard = () => {
  const [user, loading] = useAuthState(auth);

  const navigate = useNavigate();



  const [allowedUserUids, setAllowedUserUids] = useState([]);

  const goBack = () => {
    navigate(-1); // Go back to the previous page
  };

  useEffect(() => {
    // Fetch allowed user UIDs from Firestore
    const fetchAllowedUserUids = async () => {
      try {
        const adminsCollection = collection(db, "admins");
        const adminsSnapshot = await getDocs(adminsCollection);
    
        const uids = adminsSnapshot.docs.map((doc) => {
          const data = doc.data();
          return data.userId;
        });
    
        setAllowedUserUids(uids);
      } catch (error) {
        console.error("Error fetching allowed user UIDs: ", error);
      }
    };

    fetchAllowedUserUids();
  });
  return (
    <>
      {loading ? (
        <>
          <div className="container__page dashboard subpage">
            <Loader1 />
          </div>
        </>
      ) : (
        <>
          <div className="container__page dashboard subpage">
            <button className="backButtonUni" onClick={goBack}>
              Back
            </button>
            <Link to="/" style={{ marginBottom: "20px" }}>
              Home
            </Link>
            <div className="page__wrapper">
              {user && allowedUserUids.includes(user.uid) ? (
                <Link to="/dashboard/admin">Error Form</Link>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
