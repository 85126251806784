// Import necessary modules and components
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import "./styles/global/uni.scss";
import { Layout, NoPage, Loader1 } from "./components/global";
import {
  Home,
  TechIssues,
  Login,
  Register,
  Admin,
  Dashboard,
} from "./components/pages";
import Issue from "./components/pages/techIssues/issues/Issue";
import TaskForm from "./components/pages/admin/Admin";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "./hooks/firebase";
import { collection, getDocs } from "firebase/firestore";

const TechIssuesPage = () => {
  // const { issueCode } = useParams();

  const [issues, setIssues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [allowedUserUids, setAllowedUserUids] = useState([]); // State to store allowed user UIDs
  const [user] = useAuthState(auth);

  useEffect(() => {
    // Fetch allowed user UIDs from Firestore
    const fetchAllowedUserUids = async () => {
      try {
        const adminsCollection = collection(db, "admins");
        const adminsSnapshot = await getDocs(adminsCollection);
    
        const uids = adminsSnapshot.docs.map((doc) => {
          const data = doc.data();
          return data.userId;
        });
    
        setAllowedUserUids(uids);
      } catch (error) {
        console.error("Error fetching allowed user UIDs: ", error);
      }
    };
    

    // Fetch issues from Firestore
    const fetchIssues = async () => {
      try {
        const issuesCollection = collection(db, "issues");
        const issuesSnapshot = await getDocs(issuesCollection);

        const fetchedIssues = [];
        issuesSnapshot.forEach((doc) => {
          const data = doc.data();
          fetchedIssues.push(data);
        });

        setIssues(fetchedIssues);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching issues: ", error);
      }
    };

    fetchAllowedUserUids(); // Fetch allowedUserUids when the component mounts
    fetchIssues(); // Fetch issues when the component mounts

    const noscriptElement = document.getElementById("noscript"); // Get the DOM element with id 'noscript'
    if (noscriptElement) {
      noscriptElement.remove(); // Remove the 'noscript' element from the DOM if it exists
    }
  }, []);

  return (
    <Routes>
      <Route index element={<TechIssues />} />
      {user && allowedUserUids ? (
        <Route
          path="edit/:issueCode"
          element={<TaskForm />}
        />
      ) : (
        <></>
      )}

      {isLoading ? (
        // Render a loading route if isLoading is true
        <Route
          path={`:issueCode`}
          element={
            <>
              <div className="container__page i">
                <div className="page__wrapper">
                  <Loader1 />
                </div>
              </div>
            </>
          }
        />
      ) : (
        // Map through issues and render corresponding Issue components
        <>
          {issues
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((issue) => (
              <Route
                key={issue.issueCode} // Use issueCode as the key
                path={`:issueCode`}
                element={<Issue {...issue} />}
              />
            ))}
        </>
      )}
    </Routes>
  );
};

// Define the main App component
function App() {
  // State for storing the fetched issues and loading status
  // const [issues, setIssues] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const [allowedUserUids, setAllowedUserUids] = useState([]); // State to store allowed user UIDs
  const [user] = useAuthState(auth);

  // Fetch issues when the component mounts
  useEffect(() => {
    // Fetch all issues using the getIssues function
    const fetchAllowedUserUids = async () => {
      try {
        const adminsCollection = collection(db, "admins");
        const adminsSnapshot = await getDocs(adminsCollection);

        const uids = [];
        adminsSnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.userId) {
            uids.push(data.userId);
          }
        });

        setAllowedUserUids(uids);
      } catch (error) {
        console.error("Error fetching allowed user UIDs: ", error);
      }
    };

    fetchAllowedUserUids(); // Fetch allowedUserUids when the component mounts
  }, []);

  return (
    <Router>
      {/* Set up routing using the Routes component */}
      <React.Suspense
        fallback={
          <>
            <div className="container__page issue">
              <div className="page__wrapper">
                <div className="wrapper__issues">
                  <Loader1 />
                </div>
              </div>
            </div>
          </>
        }
      >
        <Routes>
          {/* Define the main route */}
          <Route path="/" element={<Layout />}>
            {/* Render the Home component for the root path */}
            <Route index element={<Home />} />
            <Route path="loading" element={<Loader1 />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="dashboard">
              <Route index element={<Dashboard />} />
              {user && allowedUserUids.includes(user.uid) ? (
                <Route path="admin" element={<Admin />} />
              ) : (
                <></>
              )}
            </Route>

            {/* Define a nested route for tech-issues */}
            <Route path="tech-issues/*" element={<TechIssuesPage />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </React.Suspense>
    </Router>
  );
}

// Export the App component as the default export
export default App;
