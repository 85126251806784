import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  logInWithEmailAndPassword,
  signInWithGoogle,
} from "../../../../hooks/firebase";
import {
  query,
  collection,
  getDocs,
  where
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

import { db } from "../../../../hooks/firebase";

import { googleLogo } from "../../../../assets/images";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // Display a loading indicator here if needed
      return;
    }

    if (user) {
      // You can navigate to the dashboard and write user data here
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      getDocs(q)
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {

            // if (!docData.pmcLevel) {
            //   // Set pmcLevel to 1 if it doesn't exist
            //   updateDoc(querySnapshot.docs[0].ref, { pmcLevel: 1 });
            // }

            // if (!docData.tarkovEdition) {
            //   // Set tarkovEdition to "Standard Edition" if it doesn't exist
            //   updateDoc(querySnapshot.docs[0].ref, { tarkovEdition: "Standard Edition" });
            // }
          }

          navigate("/dashboard");
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, [user, loading, navigate]); // Include 'navigate' in the dependency array

  const handleLogin = async () => {
    // Validate email and password before attempting login
    if (!email || !password) {
      alert("Please enter both email and password.");
      return;
    }

    try {
      // const userCredential = await
      logInWithEmailAndPassword(email, password);
      // const user = userCredential.user;
    } catch (error) {
      // Handle login error and display it to the user
      alert("Login failed: " + error.message);
      console.error("Login error:", error);
    }
  };

  return (
    <div className="container__page login">
      <div className="login__container">
        <Link to="/">Back</Link>
        <h1>Login</h1>
        <input
          type="text"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button className="login__btn" onClick={handleLogin}>
          Login
        </button>
        <button className="login__btn login__google" onClick={signInWithGoogle}>
          <img src={googleLogo} alt="Google Logo" />
          Login with Google
        </button>
        <div>
          <Link to="/reset">Forgot Password</Link>
        </div>
        <div>
          Don't have an account? <Link to="/register">Register</Link> now.
        </div>
      </div>
    </div>
  );
}

export default Login;
