// Import the functions you need from the SDKs you need
import { useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAGjCW_2lczasz5TUQSwh4Yq0n6F1vP-7M",
  authDomain: "tarkov-support-2c9e6.firebaseapp.com",
  projectId: "tarkov-support-2c9e6",
  storageBucket: "tarkov-support-2c9e6.appspot.com",
  messagingSenderId: "382720333370",
  appId: "1:382720333370:web:a56a060a2f37494d49c703"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential; // Return userCredential on success
  } catch (err) {
    console.error(err);
    throw new Error("Login failed: " + err.message); // Throw an error on failure
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};
function writeUserData(user) {
  // Check if the user object is valid and not null
  if (user) {
    // Assuming you have a "users" collection in Firestore
    const usersCollection = collection(db, "users");

    // Use addDoc to add a new document to the "users" collection
    addDoc(usersCollection, user)
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  } else {
    console.error("Invalid user data: user is null");
  }
}
async function addInfoToUser(uid, dataToAdd) {
  // Reference the "users" collection and the specific user document by UID
  const usersCollection = collection(db, "users");
  const userDoc = doc(usersCollection, uid);

  try {
    // Use getDoc to retrieve the user's document data
    const userDocSnapshot = await getDoc(userDoc);

    // Check if the document exists
    if (userDocSnapshot.exists()) {
      // Get the current data
      const userData = userDocSnapshot.data();

      // Merge the current data with the new dataToAdd
      const updatedData = { ...userData, ...dataToAdd };

      // Use setDoc to update the user's data
      await setDoc(userDoc, updatedData);

      console.log("User data updated successfully.");
    } else {
      console.error("User document not found.");
    }
  } catch (error) {
    console.error("Error updating user data: ", error);
  }
}
const FirebaseInitializer = ({ children }) => {
  // Perform any Firebase setup or checks here
  useEffect(() => {
    // You can add any Firebase setup code here if needed
  }, []);

  return children;
};
const usersCollection = collection(db, "users");
export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  writeUserData,
  addInfoToUser,
  usersCollection,
  FirebaseInitializer
};
