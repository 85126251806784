import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { auth, logout } from "../../../hooks/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const Home = () => {
  const [user, loading] = useAuthState(auth);

  const [action_1, setAction_1] = useState("Login"); // Initialize with a default value
  const [action_2, setAction_2] = useState("Register"); // Initialize with a default value

  useEffect(() => {
    if (loading) {
      console.log("Loading...");
      // Display a loading indicator here if needed
    } else if (user) {
      setAction_1("Dashboard");
      setAction_2("Sign Out");
    } else {
      setAction_1("Login");
      setAction_2("Register");
    }
  }, [user, loading]);

  return (
    <div className="container__page home">
      <div className="page__wrapper">
        {/* Title Section */}
        <div className="wrapper__title">
          <h1 className="title__h1">Tarkov Support</h1>
          <h2 className="title__version">v2.1.x</h2>
        </div>

        {/* Navigation Section */}
        <div className="wrapper__navigation">
          <Link className="navigation__item" to="/tech-issues">
            Tech Issues
          </Link>
          <div className="divider"></div>
          <Link
            className="navigation__item auth"
            to={action_1 === "Dashboard" ? "/dashboard" : "/login"}
          >
            {action_1}
          </Link>
          <Link
            className="navigation__item auth"
            to={action_2 === "Register" ? "/register" : ""}
            onClick={logout}
          >
            {action_2}
          </Link>
          {/* Uncomment and modify links when needed */}
          {/* <Link className="navigation__item" to="https://www.gofundme.com/f/tarkov-support-website?utm_source=customer&utm_medium=copy_link&utm_campaign=p_lico+share-sheet-first-launch">
            Donate
          </Link> */}
        </div>

        {/* Credit Section */}
        <div className="wrapper__credit">
          <h3>Our other Sites:</h3>
          <a
            href="https://tarkov.press"
            className="discord"
            style={{ marginBottom: "20px" }}
          >
            Tarkov.press
          </a>
          <a
            href="https://tarkov.support"
            className="discord"
            style={{ marginBottom: "20px" }}
          >
            Tarkov.support
          </a>
          <a className="discord" href="https://discord.gg/YFwgGZ9kZf">
            Our Discord Server
          </a>
          <p>
            <span className="discord_user">Zosd</span> on Discord
          </p>
          <a
            className="buymeacoffee"
            href="https://www.buymeacoffee.com/cadenmfink"
          >
            <img
              src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=cadenmfink&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff"
              alt="Buy me a coffee"
            />
          </a>
        </div>

        {/* Image Section */}
        <div className="wrapper__image">
          {/* You can add image content here */}
        </div>
      </div>
    </div>
  );
};

export default Home;
