import React from "react";
import { Outlet, Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="header" id="header">
      <ul className="header__ul">
        <li className="ul__li">
          <Link className="li__Link" to="/">
            Home
          </Link>
          {/* <Link className="li__Link" to="/">
            Placeholder
          </Link>
          <Link className="li__Link" to="/">
            Placeholder
          </Link>
          <Link className="li__Link" to="/">
            Placeholder
          </Link> */}
        </li>
        <a
          className="buymeacoffee"
          href="https://www.buymeacoffee.com/cadenmfink"
        >
          <img
            src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=cadenmfink&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff"
            alt="Buy Me a coffee"
          />
        </a>
      </ul>
    </header>
  );
};

const Layout = () => {
  return (
    <>
      <div className="container">
        <Outlet />
      </div>
    </>
  );
};

const NoPage = () => {
  return (
    <>
      <div className="container__page nopage">
        <div className="page__wrapper">
          <Header />
          <div className="wrapper__nopage">
            <h1>
              "{window.location.pathname.replace("/", "")}" does not exist
            </h1>
            <Link to="/">Home</Link>
          </div>
        </div>
      </div>
    </>
  );
};

const Loader1 = () => {
  return (
    <>
      <div className="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
};

export { Layout, Header, NoPage, Loader1 };
