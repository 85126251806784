// Import necessary modules and components
import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Header, Loader1 } from "../../global";
import {
  collection,
  query,
  getDocs,
} from "firebase/firestore";

import { db } from "../../../hooks/firebase";

// Define the TechIssues component
const TechIssues = () => {
  // State to hold the fetched issues and loading status
  const [issues, setIssues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchErrors = useCallback(async () => {
    try {
      const q = query(collection(db, "issues"));
      const querySnapshot = await getDocs(q);

      const issueData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // console.log("Issue Data:", issueData);

      setIssues(issueData);
      setIsLoading(false); // Set loading status to false once data is fetched

    } catch (error) {
      console.error("Error fetching issues:", error);
      setIsLoading(false); // Set loading status to false on error
    }
  }, []);

  useEffect(() => {
    fetchErrors();
  }, [fetchErrors]);

  // Render the component's JSX
  return (
    <div className="container__page tech-issues">
      <div className="page__wrapper">
        <Header /> {/* Render the Header component */}
        <div className="wrapper__issues">
          {/* Conditional rendering based on isLoading */}
          {isLoading ? (
            <Loader1 />
          ) : (
            // Map through issues and render a Link for each issue
            issues.map((issue) => (
              <Link
                className="issues__issue"
                to={`/tech-issues/${issue.id}`} // Use "id" as the key
                key={issue.id} // Use "id" as the key
              >
                {/* Display issue details */}
                <span className="issue__title">{issue.title}</span>
                <span className="issue__code">{issue.issueCode}</span>
                <span className="issue__date">{issue.date}</span>
              </Link>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

// Export the TechIssues component as the default export
export default TechIssues;
